
import { defineComponent, ref, onMounted, computed, onUnmounted, onBeforeMount, reactive, Ref } from 'vue'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import { useStore } from 'vuex'
import { Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { Company } from '@/store/modules/CompanyModule'
import router from '@/router/clean'
import { LoginHistoryPayload, Metrics, MetricsPayload } from '@/store/modules/StatisticModule'
import DataTable from '@/components/kt-datatable/KTDatatable.vue'
import { formatListDateTime } from '@/core/helpers/dateFormatter'

function useVuex<T> (getState: () => T): Ref<T> {
  const store = useStore()
  const data = ref<T>(getState()) as Ref<T>
  const unwatch = store.watch<T>(
    getState,
    (newVal: T) => {
      data.value = newVal
    }
  )
  onUnmounted(() => {
    unwatch()
  })
  return data
}

export default defineComponent({
  name: 'overview-company',
  components: {
    DataTable
  },
  setup () {
    const checkedCustomers = ref([])
    const store = useStore()
    const isGravatarLoaded = ref<boolean>(false)
    const isGravatarLoading = ref<boolean>(false)
    const tableHeader = ref([
      {
        name: 'Account name',
        key: 'name',
        sortable: true
      },
      {
        name: 'Login date',
        key: 'date',
        sortable: true
      }
    ])
    const itemsPerPage = ref<number>(5)
    const selectedPage = ref<number>(0)
    const tableData = ref(computed(() => store.getters.getLoginHistories))
    const historyCount = computed(() => store.getters.getLoginHistoriesCount)
    const pageCount = computed(() => Math.ceil(historyCount.value / itemsPerPage.value))

    const state = reactive({
      company: useVuex<Company>(() => store.getters.getCompany)
    })
    const company = ref(computed(() => store.getters.getCompany)) as unknown as Company
    const metrics = ref(computed(() => store.getters.getMetrics)) as unknown as Metrics
    const uuid = router.currentRoute.value.params.id

    onBeforeMount(() => {
      store.dispatch(MutationsActions.API_GET_MUTATE_COMPANY_BY_UUID, uuid)
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs('Company Overview ', ['Companies'])
      const filter: MetricsPayload = { metrics: ['num_sessions', 'num_accounts', 'num_unique_users'], filter: { companies: [uuid] } }
      store.dispatch(MutationsActions.API_GET_MUTATE_METRICS_BY_FILTER, filter)
      isGravatarLoading.value = true
      const loginHistoryFilter: LoginHistoryPayload = { filter: { companyUuid: uuid, limit: itemsPerPage.value, offset: selectedPage.value } }
      const loginHistoryCountFilter: LoginHistoryPayload = { filter: { companyUuid: uuid } }
      store.dispatch(MutationsActions.API_GET_MUTATE_LOGIN_HISTORY_BY_FILTER, loginHistoryFilter)
      store.dispatch(MutationsActions.API_GET_MUTATE_LOGIN_HISTORY_COUNT_BY_FILTER, loginHistoryCountFilter)
    })
    onUnmounted(() => {
      store.commit(Mutations.SET_COMPANY, {})
    })

    const onGravatarLoaded = () => {
      isGravatarLoading.value = false
      isGravatarLoaded.value = true
    }

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        // deleteCustomer(item)
      })
      checkedCustomers.value.length = 0
    }

    const onItemsPerPageChange = (val) => {
      itemsPerPage.value = val
      const loginHistoryFilter: LoginHistoryPayload = { filter: { companyUuid: uuid, limit: itemsPerPage.value, offset: (itemsPerPage.value * (selectedPage.value - 1)) } }
      store.dispatch(MutationsActions.API_GET_MUTATE_LOGIN_HISTORY_BY_FILTER, loginHistoryFilter)
    }

    const onCurrentPageChanged = (page) => {
      selectedPage.value = page
      const loginHistoryFilter: LoginHistoryPayload = { filter: { limit: itemsPerPage.value, offset: (itemsPerPage.value * (selectedPage.value - 1)) } }
      store.dispatch(MutationsActions.API_GET_MUTATE_LOGIN_HISTORY_BY_FILTER, loginHistoryFilter)
    }

    const gravatarUrl = (email) => {
      const md5 = require('md5')
      const hash = md5((email.trim().toLowerCase()))
      return 'https://www.gravatar.com/avatar/'.concat(hash, '?s=150').concat('&d=identicon')
    }

    return {
      checkedCustomers,
      deleteFewCustomers,
      onItemsPerPageChange,
      onCurrentPageChanged,
      company,
      state,
      metrics,
      gravatarUrl,
      onGravatarLoaded,
      isGravatarLoaded,
      isGravatarLoading,
      tableHeader,
      tableData,
      pageCount,
      itemsPerPage,
      historyCount,
      selectedPage,
      formatListDateTime
    }
  }
})
